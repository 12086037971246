import React, { useEffect, useState } from 'react';
import OnboardingPageLayout from './OnboardingPageLayout/OnboardingPageLayout';
import EducationStep from './EducationStep';
import DriverAndImpactStep from './DriverAndImpactStep';
import SubjectsStep from './SubjectsStep';
import InterestsStep from './InterestsStep';
import { CareerOnboarding, CareerSubject, ImpactOption, InspirationOption } from 'labxchange-client';
import { CareersApi } from 'global/api';
import { getLoggedInStatus, getUsername } from 'auth/selectors';
import { ROUTES } from 'global/constants';
import { history } from 'global/history';
import { useSelector } from 'react-redux';
import { RegistrationGate } from 'ui/components/RegistrationGate';
import messages from './displayMessages';
import uiMessages from 'ui/components/displayMessages';
import { intl } from 'i18n';

export interface OnboardingProps {
    userStep?: number;
}

export const Onboarding: React.FC<OnboardingProps> = ({userStep=1}) => {
    const [currentStep, setCurrentStep] = useState(userStep);
    const [onboarding, setOnboarding] = useState<CareerOnboarding>();
    const [selectedSubjects, setSelectedSubjects] = useState<CareerSubject[]>([]);
    const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
    const [selectedImpacts, setSelectedImpacts] = useState<ImpactOption[]>([]);
    const [selectedInspirations, setSelectedInspirations] = useState<InspirationOption[]>([]);
    const [selectedQuestion, setSelectedQuestion] = useState<string>('');
    const isLoggedIn = useSelector(getLoggedInStatus);
    const loggedInUsername = useSelector(getUsername);

    useEffect(() => {
        const fetchOnboardingData = async () => {
            try {
                const response: CareerOnboarding = await CareersApi.onboarding();
                setOnboarding(response);
            } catch (error) {
                // tslint:disable-next-line:no-console
                console.error(error);
            }
        };

        fetchOnboardingData();
    }, []);

    const toggleSubjectSelection = (subject: CareerSubject) => {
        setSelectedSubjects((prevSelected) =>
            prevSelected.includes(subject)
                ? prevSelected.filter((selected) => selected !== subject)
                : [...prevSelected, subject]
        );
    };

    const toggleInterests = (interests: string[]) => {
        setSelectedInterests(interests);
    };

    const toggleImpacts = (option: ImpactOption) => {
        if (selectedImpacts.includes(option)) {
            setSelectedImpacts(selectedImpacts.filter(selected => selected !== option));
        } else {
            setSelectedImpacts([...selectedImpacts, option]);
        }
    };

    const toggleInspirations = (option: InspirationOption) => {
        if (selectedInspirations.includes(option)) {
            setSelectedInspirations(selectedInspirations.filter(selected => selected !== option));
        } else {
            setSelectedInspirations([...selectedInspirations, option]);
        }
    };

    const toggleQuestion = (question: string) => {
        setSelectedQuestion(question);
    };

    const onButtonClick = (nextStep: number) => {
        setCurrentStep(nextStep);
    };

    const onSubmit = async () => {
        const data : any = {};

        if (selectedSubjects.length > 0) {
            data.favouriteSubjects = selectedSubjects.map(subject => subject.id);
        }

        if (selectedInterests.length > 0) {
            data.interests = JSON.stringify(selectedInterests);
        }

        if (selectedImpacts.length > 0) {
            data.impact = selectedImpacts;
        }

        if (selectedInspirations.length > 0) {
            data.inspirations = selectedInspirations;
        }

        if (selectedQuestion) {
            data.educationPlan = selectedQuestion;
        }

        try {
            await CareersApi.profilePartialUpdate({
                id: loggedInUsername,
                data
            });
        } catch (error) {
            // tslint:disable-next-line:no-console
            console.error(error);
        }

        history.push(ROUTES.CareerExplorer.HOME);
    };

    const renderRegisterationGate = () => {
        return (
            <RegistrationGate
                title={intl.formatMessage(messages.careerOnboardingAlertTitle)}
                body={intl.formatMessage(messages.careerOnboardingAlertBody)}
                primaryButtonText={uiMessages.uiSignUp}
                secondaryButtonText={uiMessages.uiSignIn}
                image='/assets/images/access.svg'
            />
        );
    };

    const renderSelectedStep = () => {
        switch (currentStep) {
            case 1:
                return <SubjectsStep
                    currentStep={currentStep}
                    subjectAreas={onboarding?.subjectAreas}
                    selectedSubjects={selectedSubjects}
                    onButtonClick={onButtonClick}
                    toggleSubjectSelection={toggleSubjectSelection}
                />;
            case 2:
                return <InterestsStep
                    currentStep={currentStep}
                    interestsOptions={onboarding?.interestOptions}
                    selectedInterests={selectedInterests}
                    onButtonClick={onButtonClick}
                    toggleInterests={toggleInterests}
                />;
            case 3:
                return <DriverAndImpactStep
                    currentStep={currentStep}
                    selectedImpacts={selectedImpacts}
                    onButtonClick={onButtonClick}
                    impactOptions={onboarding?.impactOptions}
                    toggleImpacts={toggleImpacts}
                />;
            case 4:
                return <DriverAndImpactStep
                    currentStep={currentStep}
                    selectedInspirations={selectedInspirations}
                    onButtonClick={onButtonClick}
                    inspirationOptions={onboarding?.inspirationOptions}
                    toggleInspirations={toggleInspirations}
                />;
            case 5:
                return <EducationStep
                    currentStep={currentStep}
                    educationQuestions={onboarding?.educationQuestions || []}
                    selectedQuestion={selectedQuestion}
                    toggleQuestion={toggleQuestion}
                    onButtonClick={onButtonClick}
                    onSubmit={onSubmit}
                />;
            default:
                return <OnboardingPageLayout title='' description='' currentStep={currentStep} onButtonClick={onButtonClick} />;
        }
    };

    return (
        <>
            {isLoggedIn ? renderSelectedStep() : renderRegisterationGate()}
        </>
    );
};

export default Onboarding;
