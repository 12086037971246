import * as React from 'react';
import { connect } from 'react-redux';
import { LxConfig, ROUTES } from '../../../global/constants';
import { Redirect } from 'react-router';
import bind from 'bind-decorator';
import { AuthPageLayout, Button, CalendarDate, showErrorMessage } from 'ui/components';
import * as LoginActions from 'auth/actions';
import { RootState } from 'global/state';
import { getLoggedInStatus } from 'auth/selectors';
import { isLxLoginActive, AuthProviderEnum, validatePassword, authLocalStorageManager, ProgressPercentageEnum } from 'auth/utils';
import messages from '../displayMessages';
import { SSOComponent } from '../elements/SSOComponent';
import { intl } from 'i18n';
import { WrappedMessage } from 'utils';
import { history } from 'global/history';
import { showLoginModal } from '../LxLoginView/dispatch';
import { EmailSignUpComponent } from '../elements/EmailSignUpComponent';
import * as EmailValidator from 'email-validator';
import { TermsAndPolicyComponent } from '../elements/TermsAndPolicyComponent';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';
import { isValidEmailTLD } from 'auth/utils';

type LoginPage = 'SSO' | 'email';

interface Props {
    isLoggedIn: boolean;
    loginUser: typeof LoginActions.login;
}

interface State {
    name: string;
    birthdate?: CalendarDate;
    email: string;
    password: string;
    showPassword: boolean;
    newsletterCheck: boolean;
    page: LoginPage;
    invalidBirthdate: boolean;
    invalidEmail: boolean;
    invalidPassword: boolean;
    onSignUp: boolean;
}


@connect((state: RootState) => ({
    isLoggedIn: getLoggedInStatus(state),
}), {
    loginUser: LoginActions.login,
})
export class LXSignUpView extends React.PureComponent<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            showPassword: false,
            newsletterCheck: false,
            page: 'SSO',
            invalidBirthdate: false,
            invalidEmail: false,
            invalidPassword: false,
            onSignUp: false,
        };
    }

    public render() {
        if (this.props.isLoggedIn || !isLxLoginActive()) {
            return <Redirect to={ROUTES.Explore.HOME} push={true} />;
        }
        const signupUsingJoinClass = authLocalStorageManager.signupUsingJoinClassButton;
        const pageTitle = signupUsingJoinClass ? messages.lxAuthSignUpJoinClassTitle : messages.lxAuthSignUpTitle;
        return (
            <AuthPageLayout
                pageTitle={pageTitle}
                className='sign-up-page'
                imageUrl='/assets/images/auth-layouts/astronaut.svg'
                title={intl.formatMessage(pageTitle)}
                subtitle={intl.formatMessage(this.renderSubtitle())}
                enableBackButton={this.state.page === 'email'}
                onClickBackButton={this.onClickBackButton}
                progressPercentage={this.state.page === 'email' ? ProgressPercentageEnum.SignupPage : undefined}
            >
                <div className='sign-up-form'>
                    {this.renderForm()}
                    <TermsAndPolicyComponent
                        type='sign-up'
                    />
                    <div className='sign-in-container'>
                        <div className='sign-in-text'>
                            {intl.formatMessage(messages.lxAuthAlreadyAccountQuestion)}
                        </div>
                        <Button
                            className='sign-in-button'
                            btnStyle='link'
                            label={messages.lxAuthSignInLavel}
                            onClick={this.onClickSignIn}
                        />
                    </div>
                </div>
            </AuthPageLayout>
        );
    }

    private onClickSignIn() {
        history.push(ROUTES.All.HOME);
        showLoginModal();
    }

    @bind private onClickBackButton() {
        this.setState({
            page: 'SSO',
            invalidBirthdate: false,
            birthdate: undefined,
        });
    }

    @bind private onClickProvider(provider: AuthProviderEnum) {
        analyticsInstance.track(
            EVENT_NAMES.SignUpSSOProviderClicked,
            { provider: provider.toLowerCase(), url: window.location.toString() },
            { sendImmediately: true },
        );

        authLocalStorageManager.signUpMedium = provider;

        if (provider === AuthProviderEnum.Email) {
            this.setState({ page: 'email' });
        }
        else if (provider === AuthProviderEnum.Edx) {
            /// Login with edx. This calls the old auth flow.
            this.props.loginUser();
        }
        else {
            LoginActions.loginWithProvider(provider);
        }
    }

    @bind private onChangeName(name: string) {
        this.setState({ name });
    }

    @bind private onChangeBirthdate(birthdate: string) {
        if (!birthdate) {
            this.setState({
                birthdate: undefined,
                invalidBirthdate: false,
            });
            return;
        }

        /// Birthdate validation
        const calendarBirthdate = CalendarDate.fromIsoString(birthdate);
        const invalidBirthdate = calendarBirthdate.ageInYears < 13;

        this.setState({
            birthdate: calendarBirthdate,
            invalidBirthdate,
        });
    }

    @bind private onChangeEmail(email: string) {
        this.setState({ email: email.toLowerCase(), invalidEmail: false });
    }

    /**
     * We use this function for email validation.
     * This function only runs when the user blurs the email input
     */
    @bind private onEmailFocusOut(email: string) {
        let invalidEmail;
        if (email.length === 0) {
            invalidEmail = false;
        }
        else {
            invalidEmail = !EmailValidator.validate(email) || !isValidEmailTLD(email);
        }

        this.setState({ email, invalidEmail });
    }

    @bind private onChangePassword(password: string) {
        this.setState({ password, invalidPassword: false });
    }

    @bind private onPasswordFocusOut(password: string) {
        this.setState({ password, invalidPassword: !validatePassword(password)});
    }

    @bind private onChangeShowPassword(showPassword: boolean) {
        this.setState({ showPassword });
    }

    @bind private onNewletterCheck(newsletterCheck: boolean) {
        this.setState({ newsletterCheck });
    }

    private renderForm() {
        switch (this.state.page) {
            case 'SSO':
                return this.renderSSOComponent();
            case 'email':
                return this.renderEmailComponent();
        }
    }

    @bind private renderSubtitle() {
        switch (this.state.page) {
            case 'SSO':
                return messages.lxAuthSignUpSubtitle;
            case 'email':
                return messages.lxAuthSignUpWithEmailSubtitle;
        }
    }

    private renderSSOComponent() {
        return (
            <>
                <SSOComponent
                    providers={LxConfig.EnabledAuthProviders}
                    topMessage={messages.lxAuthSignUpAgeWarning}
                    checkValue={this.state.newsletterCheck}
                    bottomMessage={messages.lxAuthSignUpNewsletterMessage}
                    onClickProvider={this.onClickProvider}
                    onCheck={this.onNewletterCheck}
                />
            </>
        );
    }

    private renderEmailComponent() {
        return (
            <>
                <EmailSignUpComponent
                    name={this.state.name}
                    birthdate={this.state.birthdate?.isoString || ''}
                    email={this.state.email}
                    password={this.state.password}
                    showPassword={this.state.showPassword}
                    invalidPassword={this.state.invalidPassword}
                    bottomMessage={messages.lxAuthSignUpNewsletterMessage}
                    invalidBirthday={this.state.invalidBirthdate}
                    invalidEmail={this.state.invalidEmail}
                    onChangeName={this.onChangeName}
                    onChangeEmail={this.onChangeEmail}
                    onEmailFocusOut={this.onEmailFocusOut}
                    onChangeBirthdate={this.onChangeBirthdate}
                    onChangePassword={this.onChangePassword}
                    onChangeShowPassword={this.onChangeShowPassword}
                    onPasswordFocusOut={this.onPasswordFocusOut}
                    onCheck={this.onNewletterCheck}
                    disableSignUp={!this.canSignUp()}
                    onSignUp={() => this.onSignUp()}
                    newsletterChecked={this.state.newsletterCheck}
                />
            </>
        );
    }

    @bind private canSignUp() {
        /// If the user has clicked the sign-up button
        /// so we need to disable the button.
        if (this.state.onSignUp) {
            return false;
        }

        const validEmail = EmailValidator.validate(this.state.email);

        /// We validate the fields
        return !this.state.invalidBirthdate && this.state.birthdate !== undefined &&
            validEmail &&
            this.state.name.length !== 0 &&
            validatePassword(this.state.password);
    }

    @bind private async onSignUp() {
        this.setState({ onSignUp: true });

        /// Build data for request
        const formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('birthdate', this.state.birthdate?.isoString || '');
        formData.append('email', this.state.email);
        formData.append('password', this.state.password);
        formData.append('subscribe_to_newsletter', this.state.newsletterCheck.toString());

        let response;

        try {
            /// This response login the user on django and returns
            /// a `Set-Cookie` header to set the `labxchange_sessionid` cookie.
            ///
            /// By default the `Set-Cookie` doesn't works. It is nedeed the `credentials`
            /// param for it to work and save the cookie.
            /// Ref: https://stackoverflow.com/a/46481100
            response = await fetch(`${LxConfig.BackendBaseUrl}/api/v1/users/sign_up/`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
            });
        }
        catch {
            /// When the servers returns an 500 error the frontend generates a CORS error
            /// because the backend does not send the Access-Control-Allow-Credentials header
            /// when unexpected errors occur
            showErrorMessage(<WrappedMessage message={messages.lxAuthUnableToSignup} />);
            return;
        }

        if (response.status === 200) {
            /// After the `labxchange_sessionid` cookie is set,
            /// we redirect to `/oauth2/authorize` to complete the login
            this.props.loginUser();
        }
        else if (response.status === 401) {
            /// Email/Password validation errors
            /// TODO If the email is already registered, another component must be displayed
            const errorMessage = (await response.json()).error_message;
            if (errorMessage) {
                showErrorMessage(errorMessage);
            }
            else {
                showErrorMessage(<WrappedMessage message={messages.lxAuthUnableToSignup} />);
            }
        }
        else if (response.status === 429) {
            /// Rate limit reached
            showErrorMessage(<WrappedMessage message={messages.lxAuthRatelimit} />);
        }
        else if (response.status === 302) {
            /// If the account exists we redirect to the respective page
            const provider = (await response.json()).provider;
            if (provider) {
                if (provider === 'email') {
                    history.push(ROUTES.General.PASSWORD_AUTH_PAGE, { email: this.state.email });
                }
                else {
                    history.push(ROUTES.General.SSO_AUTH_PAGE, { email: this.state.email, provider });
                }
            }
            else {
                showErrorMessage(<WrappedMessage message={messages.lxAuthUnableToSignup} />);
            }
        }
        else {
            showErrorMessage(<WrappedMessage message={messages.lxAuthUnableToSignup} />);
        }
        this.setState({ onSignUp: false });
    }
}
