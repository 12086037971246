import { defineMessages } from 'react-intl';

const messages = defineMessages({
    onboardingPageTitle: {
        id: 'onboardingPageTitle',
        defaultMessage: 'Career explorer onboarding',
        description: 'Page title for career explorer onboarding',
    },
    skipStepButtonLabel: {
        id: 'skipStepButtonLabel',
        defaultMessage: 'Skip question',
        description: 'Button label for skipping question on career onboarding',
    },
    skipSetupButtonLabel: {
        id: 'skipSetupButtonLabel',
        defaultMessage: 'Skip setup',
        description: 'Button label for skipping setup on career onboarding',
    },
    continueSetupButtonLabel: {
        id: 'continueSetupButtonLabel',
        defaultMessage: 'Continue',
        description: 'Button label for continuing to next step on career onboarding',
    },
    submitButtonLabel: {
        id: 'submitButtonLabel',
        defaultMessage: 'Submit',
        description: 'Button label for submiting the career onboarding data',
    },
    careerEducationOnboardingStepTitle: {
        id:'careerEducationOnboardingStepTitle',
        defaultMessage: 'What are your education plans?',
        description: 'Career onboarding education step page title',
    },
    careerEducationOnboardingStepDescription: {
        id:'careerEducationOnboardingStepDescription',
        defaultMessage: 'Some careers require further studies but not all do. Deciding on whether or not to study will help you find the right career.',
        description: 'Career onboarding education step page description',
    },
    careerDriverOnboardingStepTitle: {
        id:'careerDriverOnboardingStepTitle',
        defaultMessage: 'What drives and inspires you?',
        description: 'Career onboarding driver step page title',
    },
    careerDriverOnboardingStepDescription: {
        id:'careerDriverOnboardingStepDescription',
        defaultMessage: 'We all have things that drive us or give us purpose and fulfilment. If you don’t know these yet, you can skip this.',
        description: 'Career onboarding driver step page description',
    },
    careerImpactOnboardingStepTitle: {
        id:'careerImpactOnboardingStepTitle',
        defaultMessage: 'What impact do you want to make?',
        description: 'Career onboarding impact step page title',
    },
    careerImpactOnboardingStepDescription: {
        id:'careerImpactOnboardingStepDescription',
        defaultMessage: 'You can make a direct or indirect impact by working with people, influencing change or solving tough problems.',
        description: 'Career onboarding impact step page description',
    },
    careerSubjectStepTitle: {
        id:'careerSubjectStepTitle',
        defaultMessage: 'What are your favourite school subjects?',
        description: 'Career onboarding subjects step page title',
    },
    careerSubjectStepDescription: {
        id:'careerSubjectStepDescription',
        defaultMessage: 'We’ll suggest careers that related to these. We recommend using the search as there are a lot of subjects.',
        description: 'Career onboarding subjects step page title',
    },
    careerInterestsOnboardingStepTitle: {
        id:'careerInterestsOnboardingStepTitle',
        defaultMessage: 'What are your interests and hobbies?',
        description: 'Career onboarding interests step page title',
    },
    careerInterestsOnboardingStepDescription: {
        id:'careerInterestsOnboardingStepDescription',
        defaultMessage: 'Choose from the examples or add your own. Use common terms for best career suggestion results.',
        description: 'Career onboarding interests step page description',
    },
    careerInterestsInputPlaceholder: {
        id:'careerInterestsInputPlaceholder',
        defaultMessage: 'Interest or hobby',
        description: 'Career onboarding interests step input field placeholder',
    },
    careerInterestsInputInstructions: {
        id:'careerInterestsInputInstructions',
        defaultMessage: 'Add a comma or press enter to add',
        description: 'Career onboarding interests step input field instructions',
    },
    careerOnboardingAlertTitle: {
        id: 'careerOnboardingAlertTitle',
        defaultMessage: 'Welcome to CareerXplorer onboarding',
        description: 'Title of the alert when the user enters career onboarding without being logged in',
    },
    careerOnboardingAlertBody: {
        id: 'careerOnboardingAlertBody',
        defaultMessage: 'To continue, please sign up or log in to your account.',
        description: 'Body content of the alert when the user enters career onboarding without being logged in',
    },
});

export default messages;
